'use strict';
import Base from '../../../js/_base';

export default class PasswordFormatValidation extends Base {
    DESCRIBED_BY = 'new-password-rules';
    VALIDATION_RULES = [
        {
            regexp: '(?=.*[a-z])',
            message: '1 lowercase letter',
        },
        {
            regexp: '(?=.*[A-Z])',
            message: '1 uppercase letter',
        },
        {
            regexp: '(?=.*\\d)',
            message: '1 number',
        },
        {
            regexp: '(?=.*[^a-zA-Z0-9])',
            message: '1 special character',
        },

        {
            regexp: '^.{8,}$',
            message: '8 characters',
        },
    ];

    constructor(form) {
        super();
        this.form = form;
        this.invalid = {
            passwordFormat: true,
        };
        this.passwordInputs = this.form.querySelectorAll(this._selectors.PASSWORD_INPUT_FORMAT_VALIDATE);
        if (this.passwordInputs.length > 0) {
            this.initialize();
        }
    }

    get selectors() {
        return {
            FORM: 'form',
            INPUT: 'input',
            PASSWORD_INPUT_FORMAT_VALIDATE: '.js-format-validate',
            FORM_GROUP: '.form-group',
            FORM_PASSWORD: '.form-password',
            MESSAGE: '.message',
            ERROR: '.message.error',
            POPOVER_HEADER: '.popover-header',
            POPOVER_BODY: '.popover-body',
        };
    }

    get classList() {
        return {
            DISPLAY_NONE: 'd-none',
            DISPLAY_FLEX: 'd-flex',
            IS_VALID: 'is-valid',
            IS_INVALID: 'is-invalid',
            IS_VALID_ICON: 'icon-check-circle-outline',
            IS_INVALID_ICON: 'icon-info',
            IS_VALID_ICON_SMALL: 'icon-check',
            IS_INVALID_ICON_SMALL: 'icon-close',
            PASSWORD_POPOVER: 'password-popover',
        };
    }

    get texts() {
        return {
            IS_VALID: 'valid',
            IS_INVALID: 'invalid',
            MESSAGE_PREFIX: 'Your password must contain at least:',
            MESSAGE_ERROR: 'Your password does not meet the criteria.',
        };
    }

    initialize() {
        super.initialize();
        this.control();
    }

    control() {
        this.passwordInputs.forEach(el => {
            el.addEventListener('input', this.onChangePassword.bind(this));
            this.checkPasswordFormat(el);
        }, this);
    }

    onChangePassword(e) {
        this.checkPasswordFormat(e.target, false);
        // this.validate(e.target);
    }

    checkPasswordFormat(target = null, isOnSubmit = false) {
        if (target) {
            this.invalid.passwordFormat = false;
            const password = target.value;
            let message = '';
            let minPasswordLength = document.querySelector('input[name="min-password-length"]')?.getAttribute('value');

            this.VALIDATION_RULES.forEach(rule => {
                let isValid = false;
                let regexp = minPasswordLength
                    ? new RegExp(rule.regexp.replace('8', minPasswordLength), 'gm')
                    : new RegExp(rule.regexp, 'gm');
                if (password) isValid = regexp.test(password);
                let className = isValid ? this._classList.IS_VALID : this._classList.IS_INVALID;
                let iconClassName = isValid
                    ? this._classList.IS_VALID_ICON_SMALL
                    : this._classList.IS_INVALID_ICON_SMALL;
                let validMsg = isValid ? this._texts.IS_VALID : this._texts.IS_INVALID;
                if (!isValid) {
                    this.invalid.passwordFormat = true;
                }
                message = `${message}<li class='${className}'><i class='${iconClassName}' aria-hidden='true'></i>${
                    minPasswordLength ? rule.message.replace('8', minPasswordLength) : rule.message
                } <span class='sr-only'>${validMsg}</span></li>`;
            });

            let className = this.invalid.passwordFormat ? this._classList.IS_INVALID : this._classList.IS_VALID;
            let iconClassName = this.invalid.passwordFormat
                ? this._classList.IS_INVALID_ICON
                : this._classList.IS_VALID_ICON;
            let validMsg = this.invalid.passwordFormat ? this._texts.MESSAGE_ERROR : '';
            message = `<div class='${className}'><i class='${iconClassName}' aria-hidden='true'></i><span class='sr-only'>${validMsg}</span></div><p>${this._texts.MESSAGE_PREFIX}</p><ul class='rlist'>${message}</ul>`;
            if (target) {
                this.showPopOver(target.parentElement, message, isOnSubmit);
            }
            if (isOnSubmit) {
                this.validate(target, isOnSubmit);
            }
            return !this.invalid.passwordFormat;
        }
        return false;
    }

    showPopOver(target, message, isOnSubmit) {
        let width = window.innerWidth;
        let pos = width < 768 ? 'right' : 'right';
        let placement = ['left', 'right', 'bottom', 'right'];

        if (message) {
            let oPopover = $(target)
                .popover('dispose')
                .popover({
                    html: true,
                    content: message,
                    container: target.parentElement,
                    trigger: 'manual',
                    placement: pos,
                    fallbackPlacement: placement,
                    offset: '70px, 8px, 0',
                })
                .popover('show')
                .data('bs.popover');
            oPopover.tip?.classList.add(this._classList.PASSWORD_POPOVER);
            oPopover.tip?.querySelector(this._selectors.POPOVER_HEADER)?.remove();
            let popOverBody = oPopover.tip?.querySelector(this._selectors.POPOVER_BODY);
            popOverBody.setAttribute('id', this.DESCRIBED_BY);
            if (this.invalid.passwordFormat ** isOnSubmit) {
                // popOverBody.setAttribute('aria-live', 'polite');
            }
        }
    }

    validate(target, isOnSubmit = false) {
        this.setValid(target);
        if (this.invalid.passwordFormat) {
            this.setInValid(target, isOnSubmit);
        }
    }
    setValid(field) {
        field.setAttribute('aria-invalid', 'false');
        field.classList.remove(this._classList.IS_INVALID);

        let formGroup = field.closest(this._selectors.FORM_GROUP);
        formGroup?.classList.remove(this._classList.IS_INVALID);
        formGroup?.querySelector(this._selectors.ERROR)?.remove();
    }
    setInValid(field, isOnSubmit = false) {
        field.classList.add(this._classList.IS_INVALID);
        field.setAttribute('aria-invalid', 'true');

        if (isOnSubmit) {
            let formGroup = field.closest(this._selectors.FORM_GROUP),
                formPassword = field.closest(this._selectors.FORM_PASSWORD),
                input = formPassword?.querySelector('[aria-describedby]');

            formGroup?.classList.add(this._classList.IS_INVALID);

            formPassword?.insertAdjacentHTML(
                'beforebegin',
                `<div><div class="message error">${this._texts.MESSAGE_ERROR}</div></div>`
            );

            let errorMessage = formGroup?.querySelector('.message.error');
            errorMessage?.setAttribute('id', input?.getAttribute('aria-describedby'));
        }
    }
}
