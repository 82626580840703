'use strict';
import Base from '../../js/_base';
import '../../components/issue-item/js/issue-item';
import Alphanumeric from '../../components/alphanumeric/js/alphanumeric';
// import '../../components/article-actions-bar/js/article-actions-bar';

export default class SearchUtil extends Base {
    constructor(form) {
        super();
        this.searchPage = null;
        this._facetLinks = null;
        this.ALERT_HIDE_INTERVAL = 4000;
        this.initialize();
        this.wSLide = null;
        this.wSLideIsActive = false;
    }

    get selectors() {
        return {
            SEARCH_PAGE: '.search-page, .browse-publications, #collection-tabs',
            SEARCH_TRIGGERS:
                '.facet__list a:not(.dropdown__toggle), .search__filters__ctrl__reset, .applied-facets__remove, .search-result__sort select, .journals-categories__disciplines a, .journals-categories__subjects a, .alphanumeric__content a, .pagination__nav a.pagination__link',
            SEARCH_RESULT_BODY: '.search-result__body',
            SEARCH_RESULTS_NO_RESULTS: '.search-result__no-result',
            SEARCH_RESULT_RESULTS: '.search-result__results',
            SEARCH_RESULT_GRID: '.search-result--grid',
            SEARCH_RESULT_PAGER: '.pagination__nav',
            SEARCH_FACETS_WRAPPER: '#filter',
            SEARCH_SORT_WRAPPER: '.search-result__sort',
            LOADER_WRAPPER: '.loader-wrapper',
            W_SLIDE: '.w-slide.js--open',
            W_SLIDE_BACK: '.w-slide__back',
            W_SLIDE_BTN: '.w-slide__btn',
            W_SLIDE_BTN_ACTIVE: '.w-slide__btn.slide-active',
            W_SLIDE_CLONED_DATA: '[data-slide-clone]',
            SAVE_SEARCH_FORM: '[name="frmSaveSearch"]',
            SAVE_SEARCH_MODAL: '#saveSearchDialog',
        };
    }

    get classList() {
        return {
            DISPLAY_NONE: 'd-none',
            DISPLAY_FLEX: 'd-flex',
            ALERT: ['alert', 'alert--floating'],
            ALERT_SUCCESFUL: 'alert--success',
        };
    }

    get facetLinks() {
        let links = document.querySelectorAll(this._selectors.SEARCH_TRIGGERS);
        return links;
    }

    initialize() {
        super.initialize();
        this.searchPage = document.querySelector(this._selectors.SEARCH_PAGE);
        this.searchSort = document.querySelector(this._selectors.SEARCH_SORT_WRAPPER);
        if (this.searchPage || this.searchSort) {
            this.initFacetLinks();
            document.body.addEventListener('dateRange.change', this.onDateRangeChange.bind(this));
        }
    }

    initFacetLinks() {
        this._facetLinks = this.facetLinks;
        this._facetLinks.forEach(link => {
            if (link.tagName === 'SELECT') {
                link.addEventListener('change', this.facetOptionChangeHandler.bind(this));
            } else {
                link.addEventListener('click', this.facetLinkClickHandler.bind(this));
            }
        }, this);
    }

    onDateRangeChange(e) {
        let newURL = this.getDifferentParams(window.location.href, e.detail.url);
        this.showLoader();
        this.ajaxCall(newURL);
    }

    getDifferentParams(url1, url2) {
        function getParams(url) {
            const params = {};
            const urlSearchParams = new URL(url);
            const searchParams = new URLSearchParams(urlSearchParams.search);
            searchParams.forEach((value, key) => {
                if (!key.includes('publication')) {
                    params[key] = value;
                }
            });
            return params;
        }

        const params1 = getParams(url1);
        const params2 = getParams(url2);

        let publicationParams = url1?.match(/(?:^|&)publication%5B%5D=.*?(?=&|$)/g);

        for (const key in params2) {
            params1[key] = params2[key];
        }
        const newURLSearchParams = new URLSearchParams(params1);

        let newURL = `${new URL(url1).origin}${new URL(url1).pathname}?${newURLSearchParams.toString()}`;

        if (publicationParams?.length) {
            for (const param of publicationParams) {
                newURL += param;
            }
        }

        return newURL;
    }

    ajaxCall(url, prevText, ele, callback) {
        let _this = this;
        $.ajax({
            url: url,
            dataType: 'html',
            success: function (data) {
                let parser = new DOMParser();
                let htmlDoc = parser.parseFromString(data, 'text/html');
                _this.updataViews(htmlDoc);
                _this.updateBrowserUrl(url);
                _this.reinitWidgets();
                _this.hideLoader();
                if (UX.articleActionsbar && document.querySelector('.article-actionbar')) {
                    UX.articleActionsbar.controls();
                }
            },
            error: function () {
                _this.hideLoader();
                console.log('Failed to load AJAX search for URL: ' + url);
                _this.alert(false, 'Error while retrieving results.');
            },
        }).done(function () {
            _this.imageLazyLoad();
            new Alphanumeric();

            if (prevText) {
                let facets = document.querySelectorAll('.alphanumeric__title');

                if (facets.length) {
                    let prevSelectedEle = '';

                    facets = Array.from(facets);

                    for (const facet of facets) {
                        if (facet.textContent === prevText) {
                            prevSelectedEle = facet;
                            break;
                        }
                    }

                    let isSelected = document.querySelector('.alphanumeric__title.is--selected');

                    if (prevSelectedEle?.textContent !== 'ALL') {
                        if (isSelected?.textContent !== 'ALL') {
                            isSelected?.focus();
                        } else {
                            prevSelectedEle?.focus();
                        }
                    } else {
                        isSelected?.focus();
                    }
                }
            }

            if (ele?.classList.contains('pagination__link')) {
                window.scrollTo(0, 0);
                document.querySelector('.issue-item .sage-search-title')?.focus();
            }

            callback?.();
        });
    }

    imageLazyLoad() {
        let searchResults = document.querySelectorAll('.search-result__publications .search__item a img');
        searchResults?.forEach(image => {
            if (image.getAttribute('data-src')) {
                image.setAttribute('src', image.getAttribute('data-src'));
            }
        });
    }
    updateBrowserUrl(url) {
        history.pushState({page: url}, 'Search Results', url);
    }
    updataViews(htmlDoc) {
        const views = [
            this._selectors.SEARCH_RESULT_BODY,
            this._selectors.SEARCH_RESULT_RESULTS,
            this._selectors.SEARCH_RESULT_GRID,
            this._selectors.SEARCH_RESULT_PAGER,
            this._selectors.SEARCH_FACETS_WRAPPER,
            this._selectors.SEARCH_SORT_WRAPPER,
            this._selectors.SAVE_SEARCH_MODAL,
        ];

        this.wSlide = null;
        this.wSLideIsActive = false;
        let searchResultsIsEmpty = false;
        this.wSlide = document.querySelector(this._selectors.W_SLIDE);
        if (this.wSlide) {
            this.wSLideIsActive = true;
            document.querySelector(this._selectors.W_SLIDE_BACK)?.click();
        }

        this.alert(false, 'Results have been updated.');

        views.forEach((view, i) => {
            let newEl = htmlDoc.querySelector(view);
            let currentEl = document.querySelector(view);
            let activeSlideBtn = $(this._selectors.W_SLIDE_BTN_ACTIVE);

            if (i === 0 && !newEl) {
                newEl = htmlDoc.querySelector(this._selectors.SEARCH_RESULTS_NO_RESULTS);
            } else if (i === 0 && !currentEl) {
                currentEl = document.querySelector(this._selectors.SEARCH_RESULTS_NO_RESULTS);
            }

            if (i === 4 && !newEl) {
                const facets = currentEl?.childNodes[1];
                while (facets?.childNodes.length > 2) {
                    facets?.removeChild(facets.lastChild);
                }
                this.wSLideIsActive = false;
                if (searchResultsIsEmpty) {
                    currentEl?.remove();
                }
            }

            if (newEl && currentEl) {
                currentEl?.parentNode.replaceChild(newEl, currentEl);
                searchResultsIsEmpty = true;

                if (i === 2 && activeSlideBtn) {
                    let $slideBtn = $(this._selectors.W_SLIDE_BTN);
                    $slideBtn[0]?.classList.add('slide-active');
                }
            }
        }, this);
    }

    reinitWidgets() {
        UX.issueItem?.truncate();
        UX.toggle?.on.build();
        UX.searchFieldsCtrl.init();
        this.initFacetLinks();
        let $slideBtn = $(this._selectors.W_SLIDE_BTN_ACTIVE);
        if (this.wSLideIsActive && $slideBtn.length) {
            $slideBtn[0]?.click();
        } else {
            UX.facetDate?.init();
        }
    }

    facetLinkClickHandler(e) {
        e.preventDefault();
        let _this = e.target.closest(this._selectors.SEARCH_TRIGGERS);
        let url = _this.getAttribute('href');
        if (url) {
            this.showLoader();
            let prevText = document.querySelector('.alphanumeric__title.is--selected')?.textContent;
            if (_this.classList.contains('dropdown-item')) {
                this.ajaxCall(url);
            } else {
                this.ajaxCall(url, prevText, _this);
            }
        }
    }

    facetOptionChangeHandler(e) {
        let selectedOption = e.target.options[e.target.selectedIndex],
            url = selectedOption.getAttribute('value');

        if (url) {
            if (this.searchPage) {
                this.showLoader();
                let prevText = document.querySelector('.alphanumeric__title.is--selected')?.textContent;
                let callback = UX.exportCitation?.control;
                this.ajaxCall(url, prevText, null, callback);
            } else {
                window.location.href = url;
            }
        }
    }

    showLoader() {
        this.searchPage?.insertAdjacentHTML(
            'beforeend',
            '<div class="loader-wrapper"><div class="loader"></div></div>'
        );
    }

    hideLoader() {
        this.searchPage?.querySelector(this._selectors.LOADER_WRAPPER).remove();
    }

    // TODO: move to utilities...
    alert(status, msg) {
        let alert = document.createElement('div');
        this._classList.ALERT.forEach(c => {
            alert.classList.add(c);
        });
        alert.setAttribute('role', 'alert');
        alert.setAttribute('aria-live', 'assertive');
        alert.append(msg);
        if (status) {
            alert.classList.add(this._classList.ALERT_SUCCESFUL);
        }
        document.querySelector('body').appendChild(alert);
        window.setTimeout(() => alert.remove(), this.ALERT_HIDE_INTERVAL);
    }
}
